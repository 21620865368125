"use client";
import { motion } from "framer-motion";
import dynamic from 'next/dynamic';
import { Button } from "@/components/ui/button";
import Link from "next/link";
import { useTranslations } from "next-intl";

const DynamicImage = dynamic(() => import("next/image"), { ssr: false });

const SectionCTALayout3 = () => {
  const t = useTranslations("SectionCTALayout3");
  return (
    <section className="pb-16 dark:bg-slate-900">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 60 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            delay: 0.5,
          }}
        >
          <div className="mx-auto max-w-5xl">
            <div className="relative overflow-hidden rounded-xl px-6 py-20 text-center shadow-sm dark:shadow-slate-950/40">
              <DynamicImage
                src="/hero/gradient_creative.webp"
                className="dark:hidden"
                fill
                loading="lazy"
                sizes="100vw"
                alt="consulting hero gradient"
              />
              <DynamicImage
                src="/hero/gradient_creative_dark.webp"
                className="hidden dark:block"
                fill
                alt="consulting hero gradient"
                loading="lazy"
              />
              <div className="relative mx-auto max-w-md">
                <h2 className="mb-4">
                  {t("title")}
                </h2>
                <p className="mb-8 text-lg">
                {t("description")}
                </p>
                <Link href="/get-a-quote/">
                  <Button size="lg">{t("button")}</Button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default SectionCTALayout3;
